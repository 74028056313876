import { React, useEffect } from "react";
import { FaArrowUp } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";
import Logo from "../assets/RaveLogo.png";

function Footer() {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  });
  return (
    <section className="pt-10 questrial-regular ">
      <footer
        class="block bg-[#FDFBD4] "
        // style={{ backgroundImage: `url(${Linked3})` }}
      >
        <div class="px-5 md:px-8 ">
          <div class="mx-auto w-full">
            <div class="py-8 md:py-8 lg:py-8 flex items-center justify-center">
              <a href="/" class="mb-4 inline-block max-w-full">
                <img src={Logo} alt="" class="inline-block h-20 " />
              </a>
            </div>

            <div className="flex items-center justify-center">
            <div className="md:flex items-center justify-center mb-10">
              <div className=" border-[1px] mb-2 rounded-xl border-gray-800 py-2 px-4 w-40 mr-2 hover:-translate-y-1 hover:scale-100 duration-300">
                <a
                  href="https://www.linkedin.com/company/raveux/"
                  className="flex items-center justify-center"
                >
                  LinkedIn
                  <FaArrowUp className="rotate-45 text-xl ml-2" />
                </a>
              </div>

              <div className=" border-[1px] mb-2 rounded-xl border-gray-800 py-2 px-4 w-40 mr-2 hover:-translate-y-1 hover:scale-100 duration-300">
                <a
                  href="https://www.figma.com/file/s4umMNXJz3iF5MuGuoyh2p/RaveUX-Figma?type=design&node-id=5%3A64889&mode=design&t=XSCnuNyjLI6FADhO-1"
                  className="flex items-center justify-center"
                >
                  Figma
                  <FaArrowUp className="rotate-45 text-xl ml-2" />
                </a>
              </div>

              <div className=" border-[1px] mb-2 rounded-xl border-gray-800 py-2 px-4 w-40 mr-2 hover:-translate-y-1 hover:scale-100 duration-300">
                <a
                  href="https://www.facebook.com/raveuxdesign"
                  className="flex items-center justify-center"
                >
                  Facebook
                  <FaArrowUp className="rotate-45 text-xl ml-2" />
                </a>
              </div>
            </div>
            </div>
            <div class="flex items-center justify-center">
              <div class="max-w-[991px]:flex-none">
                <p class="text-black mb-2">
                  © Copyright 2024. All rights reserved to RaveUX
                </p>
              </div>
            </div>
          </div>

          </div>

      </footer>
    </section>
  );
}

export default Footer;
