import { useState } from 'react'
import Modal from './Modal'

function EmailPopUp() {
  const [showModal, setShowModal] = useState(false)

  return (
    <div className='h-[240px] py-20 flex flex-col items-center gap-6 text-black questrial-regular bg-white'>
      <h1 className='text-4xl font-semibold mt-4 text-center'>Need a Social Media Design Starter Kit?</h1>
        <button onClick={() => setShowModal(true)} className='bg-[#636B2F] px-4 py-3 text-lg text-white'>Free Starter Kit</button>
        {showModal && <Modal onClose={() => setShowModal(false)} />}
    </div>
  )
}

export default EmailPopUp
