import { useRef, useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function QANew() {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  });
  const FaqsCard = (props) => {
    const answerElRef = useRef();
    const [state, setState] = useState(false);
    const [answerH, setAnswerH] = useState("0px");
    const { faqsList, idx } = props;

    const handleOpenAnswer = () => {
      const answerElH = answerElRef.current.childNodes[0].offsetHeight;
      setState(!state);
      setAnswerH(`${answerElH + 20}px`);
    };
    return (
      <div
        className="space-y-3 mt-5 overflow-hidden border-b questrial-regular"
        key={idx}
        onClick={handleOpenAnswer}
      >
        <h4 className="cursor-pointer pb-5 flex items-center justify-between text-lg font-medium">
          {faqsList.q}
          {state ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 ml-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M20 12H4"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 ml-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 4v16m8-8H4"
              />
            </svg>
          )}
        </h4>
        <div
          ref={answerElRef}
          className="duration-300"
          style={state ? { height: answerH } : { height: "0px" }}
        >
          <div>
            <p className="">{faqsList.a}</p>
          </div>
        </div>
      </div>
    );
  };
  const faqsList = [
    {
      q: "Why wouldn't I just hire a freelancer or full-time designer?",
      a: "Freelancers? Time-consuming. Full-time? Inflexible. RaveUX: Scalable design expertise on-demand.",
    },
    {
      q: "What happens when I pause my subscription?",
      a: "Pause your subscription: Design on hold, files saved, no charges. Contact us via email or Slack.",
    },
    {
      q: "What programs do you design in?",
      a: "Most requests are designed using Figma.",
    },
    {
      q: "Who is on this team of designers?",
      a: "Meet your design duo: Ruth and Lisa, dedicated designer + design lead for expert eyes & top results.",
    },
    {
      q: "Are there any refunds?",
      a: "Subscriptions: No. Time & Materials: Unused hours refunded. Fixed Cost: Per project contract.**",
    },
    {
      q: "What if I don't like the design?",
      a: "Unlimited for Subscriptions (until you love it!), per contract for Fixed Costs.",
    },
  ];

  return (
    <section
      className="leading-relaxed mx-auto px-4 md:px-8 bg-white questrial-regular"
      data-aos="fade-up"
    >
      <div className="space-y-3 text-center pt-20">
        <div className="flex justify-center items-center">
          <h3 className="text-md border-[1px] border-black w-36 p-2 mb-4 text-center hover:-translate-y-1 hover:scale-100 duration-300">
            Questions
          </h3>
        </div>
        <h1 className="block md:text-5xl text-3xl font-semibold">
          Frequently Asked Questions
        </h1>
      </div>
      <div className="mt-14 max-w-2xl mx-auto">
        {faqsList.map((item, idx) => (
          <FaqsCard idx={idx} faqsList={item} />
        ))}
      </div>
    </section>
  );
}

export default QANew;
