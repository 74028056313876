import { useEffect } from "react";
import Photo1 from "../assets/Top-Notch.svg";
import Photo2 from "../assets/Embedded.svg";
import Photo3 from "../assets/Delivery.svg";
import { FaGlobeAmericas, FaCalendar, FaBolt } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";

const plans = [
  {
    name: "Monthly subscription",
    desc: "One predictable monthly fee. Pause anytime and anywhere.",
    isMostPop: true,
    details:
      "Get flexible monthly access to seasoned designers who will embed with your team, execute on your roadmap, and partner with you on your biggest product, brand, and/or marketing priorities.",
    features: [
      "1 or more designers",
      "Dedicated design lead & account manager",
      "Delivery every 48 hrs or less, Mon–Fri",
      "Embedded with your team",
      "Use the tools you already use",
      "Pause or cancel anytime",
    ],
  },
  {
    name: "Time & materials",
    desc: "Pay by the hour, only for what you need, at anytime",
    isMostPop: false,
    details:
      "Get even more flexibility. Pay only for what you need. Our team will work on retainer, and any unused hours will be refunded to you. We'll support your most pressing design needs, as they arise.",
    features: [
      "1 or more designers",
      "Dedicated design lead & account manager",
      "Delivery every 48 hrs or less, Mon–Fri",
    ],
  },
  {
    name: "Fixed project fee",
    desc: "Pay one fixed project fee. Guaranteed delivery.",
    isMostPop: false,
    details:
      "Have a specific project and deadline in mind? We'll work with you to scope a fixed-cost project, and develop a timeline for delivery. You pay one fixed project fee. No surprises. Guaranteed delivery.",
    features: [
      "1 or more designers",
      "Dedicated design lead & account manager",
      "Delivery every 48 hrs or less, Mon–Fri",
    ],
  },
];

function Access() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  });
  return (
    <section className="py-10 bg-[#FDFBD4] questrial-regular" id="plans">
      <div
        className="max-w-screen-xl mx-auto px-4 md:px-8"
        data-aos="fade-up"
      >
        <div className="flex justify-center items-center">
          <h3 className="text-md border-[1px] border-gray-800 w-36 p-2 mb-4 text-center hover:-translate-y-1 hover:scale-100 duration-300">
            Our work
          </h3>
        </div>
        <div className="relative max-w-4xl mx-auto sm:text-center ">
          <h3 className="text-5xl">
            Get flexible access to our design team
          </h3>
        </div>
        <div
          className="mt-16 justify-center gap-6 sm:grid sm:grid-cols-2 sm:space-y-0 lg:grid-cols-3"
          data-aos="fade-up"
        >
          {plans.map((item, idx) => (
            <div
              key={idx}
              className={`relative flex-1 flex items-stretch flex-col mt-6 sm:mt-0 bg-white ${
                item.isMostPop ? "mt-10" : ""
              }`}
            >
              {item.isMostPop ? (
                <span class="w-32 absolute -top-5 left-0 right-0 mx-auto px-3 py-2 border shadow-md bg-white text-center text-gray-700 text-sm">
                  Most popular
                </span>
              ) : (
                ""
              )}
              <div className="p-8 space-y-4 border-b">
                <span className="text-2xl">
                  {item.name}
                </span>

                <p className="py-2">{item.desc}</p>
                <a href="https://cal.com/raveux">
                  <button className="px-3 py-3 w-full text-md duration-150 text-white bg-[#636B2F] hover:bg-transparent hover:border-2 hover:border-black hover:text-black hover:-translate-y-1 hover:scale-100 duration-300">
                    Book a call
                  </button>
                </a>
              </div>
              <ul className="p-8 space-y-3">
                <li className="pb-2 font-medium">
                  <p className="text-[13px]">{item.details}</p>
                </li>
                {item.features.map((featureItem, idx) => (
                  <li key={idx} className="flex items-center gap-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 text-[#ADD8E6]"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    {featureItem}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>

      <div
        className="grid grid-cols-1 place-items-center"
        data-aos="fade-up"
      >
        <div className="mt-28">
          <h3 className="text-2xl">Questions?</h3>
        </div>
        <div className="mt-10 mb-4">
          <a
            href="https://cal.com/raveux"
            className="text-md w-36 py-4 px-6 mb-4 text-center text-white bg-[#636B2F] hover:-translate-y-1 hover:scale-100 duration-300"
          >
            Book a call
          </a>
        </div>
        <div className="my-2">
          <a href="https://cal.com/raveux" className="underline">
            See FAQ
          </a>
        </div>

        <div className="mt-32">
          <div className="flex items-center justify-center">
            <FaGlobeAmericas className="md:text-4xl text-2xl" />
            <h1 className="md:text-5xl text-2xl">
              Top-notch, US-based{" "}
              <span className="text-yellow-500">Talent</span>
            </h1>
          </div>
          <div className="flex items-center justify-center mt-6">
            <FaCalendar className="md:text-4xl text-2xl" />
            <h1 className="md:text-5xl text-2xl">
              Embedded <span className="text-yellow-500">with your team</span>
            </h1>
          </div>
          <div className="flex items-center justify-center mt-6 mb-20">
            <FaBolt className=" md:text-4xl text-2xl" />
            <h1 className="md:text-5xl text-2xl">
              Fast delivery{" "}
              <span className="text-yellow-500">every 48 hrs or less </span>
            </h1>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Access;
