import React from 'react'
import '../App.css'
import First1 from '../assets/Pickel.jpg'
import First3 from '../assets/First3.jpg'
import Military2 from '../assets/Military2.png'


function Horizontal() {
  return (
    <div class="relative bg-white questrial-regular">
<ul class="container-snap py-20 pb-8 px-[1vw] w-full md:flex gap-8 snap-x overflow-x-auto no-scrollbar self-center bg-transparent">
    <li class="snap-center">
        <div class="relative flex-shrink-0 max-w-[95vw] overflow-hidden md:mt-0 mt-4 ">
            <img src={First1} alt="" class="absolute inset-0 object-cover object-bottom w-full h-full " />

            <div class="absolute inset-0 w-full h-full bg-gradient-to-br from-black/75"></div>

            <div class=" relative h-96 w-[500px] p-12 flex flex-col justify-between items-start">
                <div>
                    <h2 class=" mt-3 text-3xl font-semibold tracking-tight text-white">Social Media Content</h2>
                </div>

                <a href="/Subscription" class="px-4 py-3 text-md font-medium bg-[#FDFBD4]">Get Started</a>
            </div>
        </div>
    </li>

    <li class="snap-center">
        <div class="relative flex-shrink-0 max-w-[95vw] overflow-hidden md:mt-0 mt-4 ">
            <img src={Military2} alt="" class="absolute inset-0 object-cover object-bottom w-full h-full " />

            <div class="absolute inset-0 w-full h-full bg-gradient-to-br from-black/75"></div>

            <div class=" relative h-96 w-[500px] p-12 flex flex-col justify-between items-start">
                <div>
                    <h2 class=" mt-3 text-3xl font-semibold tracking-tight text-white">Web/UX Design</h2>
                </div>

                <a href="/Subscription" class="px-4 py-3 text-md font-medium bg-[#FDFBD4]">Get Started</a>
            </div>
        </div>
    </li>


    <li class="snap-center">
        <div class="relative flex-shrink-0 max-w-[95vw] overflow-hidden md:mt-0 mt-4 ">
            <img src={First3} alt="" class="absolute inset-0 object-cover object-bottom w-full h-full " />

            <div class="absolute inset-0 w-full h-full bg-gradient-to-br from-black/75"></div>

            <div class=" relative h-96 w-[500px] p-12 flex flex-col justify-between items-start">
                <div>

                    <h2 class=" mt-3 text-3xl font-semibold tracking-tight text-white">Graphic Design</h2>
                </div>

                <a href="/Subscription" class="px-4 py-3 text-md font-medium bg-[#FDFBD4]">Get Started</a>
            </div>
        </div>
    </li>

</ul>
</div>
  )
}

export default Horizontal
