import { useEffect } from "react";
import Logo1 from "../assets/logo1.png";
import Logo2 from "../assets/logo2.png";
import Logo3 from "../assets/logo3.png";
import Logo4 from "../assets/logo4.png";
import Logo5 from "../assets/logo5.png";
import Logo6 from "../assets/logo6.png";
import "../index.css";
import AOS from "aos";
import "aos/dist/aos.css";

function StatsBanner() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  });

  return (
    <section>

        <div class="absolute inset-0 -z-10 h-full w-full"></div>
        <div className="py-2 bg-white">
          <div className=" mx-auto md:px-4 px-4 flex-wrap gap-x-6 py-4 " data-aos="fade-up">
          <div className="relative mx-auto sm:text-left md:mt-20 ml-2 font-mono">
   
        </div>
            <div className="flex">

              <div className="flex space-x-40 ">
                <img
                  src={Logo1}
                  alt="Imag"
                  className="h-20 w-40"
                />
                <img
                  src={Logo2}
                  alt="Imag 2"
                  className="h-20 w-40"
                />
                <img
                  src={Logo3}
                  alt="Imag 3"
                  className="h-20 w-40"
                />
                <img
                  src={Logo4}
                  alt="Imag 4"
                  className="h-20 w-40"
                />
                <img
                  src={Logo5}
                  alt="Imag 5"
                  className="h-20 w-40"
                />
                <img
                  src={Logo6}
                  alt="Imag 6"
                  className="h-20 w-40"
                />
                <img
                  src={Logo1}
                  alt="Imag 1"
                  className="h-20 w-40"
                />
                <img
                  src={Logo2}
                  alt="Imag 2"
                  className="h-20 w-40"
                />
                <img
                  src={Logo3}
                  alt="Imag 3"
                  className="h-20 w-40"
                />
                <img
                  src={Logo4}
                  alt="Imae 4"
                  className="h-20 w-40"
                />
                <img
                  src={Logo5}
                  alt="Imag 5"
                  className="h-20 w-40"
                />
                <img
                  src={Logo6}
                  alt="Imag 6"
                  className="h-20 w-40"
                />
              </div>
            </div>
          </div>
        </div>
    </section>
  );
}

export default StatsBanner;
