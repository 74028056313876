import {React, useEffect} from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import TeamPhoto from '../assets/TeamPhoto.mp4'

function TeamImage() {
    useEffect(() => {
        AOS.init({duration:1200})
      })
    const stats = [
        {
            data: "100+",
            title: "Customers"
        },
        {
            data: "6+",
            title: "Countries"
        },

        {
            data: "3K+",
            title: "Content Created"
        },

    ]
  return (
    <section className="py-24 bg-white questrial-regular" data-aos="fade-up">
    <div className="max-w-screen-xl mx-auto px-4 text-gray-600 gap-x-12 items-start justify-between lg:flex md:px-8">
        <div className="sm:hidden lg:block lg:max-w-xl">
        <video
              src={TeamPhoto}
              className="mx-auto object-cover md:h-[400px] md:w-[800px] h-[200px] w-[100%] md:mt-0 mt-4"
              alt="MainPhoto"
              autoPlay
          loop
          muted
            >
            </video>
        </div>
        <div className="mt-16 gap-12 sm:mt-0 md:flex lg:block">
            <div className="max-w-2xl mt-4">
                <h3 className="text-gray-700 text-3xl font-semibold sm:text-5xl">
                About Us
                </h3>
                <p className="mt-3 max-w-xl">
                We’re a creative digital agency offering comprehensive solutions to your most pressing problems. With over 7+ years in UX/UI design, we know how to elevate your user’s experience and translate that into metrics you can actually see: <span className='underline text-[#636B2F]'>more clients and bigger sales</span>.
                </p>
            </div>
          <div className="flex-none mt-6 md:mt-0 lg:mt-6">
                <ul className="inline-grid gap-y-8 gap-x-14 grid-cols-3">
                    {
                        stats.map((item, idx) => (
                            <li key={idx} className="">
                                <h4 className="text-4xl text-[#636B2F] font-semibold">{item.data}</h4>
                                <p className="mt-3 font-medium">{item.title}</p>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    </div>
</section>
  )
}

export default TeamImage
