import { React, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function WorkSection() {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  });
  return (
    <section
      class="block bg-white questrial-regular"
      id="Work"
    >
      <div class="py-16 md:py-24 lg:py-20" data-aos="fade-up">
        <div class="px-5 md:px-10">
          <div class="mx-auto w-full max-w-7xl">
            <div class="mx-auto w-full max-w-3xl">
              <div class="text-center">
                <h2
                  id="solutions"
                  class="font-semibold text-3xl md:text-5xl mb-8 md:mb-12 lg:mb-16"
                  data-aos="fade-up"
                >
                  {" "}
                  Our Core Solutions
                </h2>
                {/* <div class="mx-auto mt-4 max-w-[528px] mb-8 md:mb-12 lg:mb-16">
                <p class="text-[#636262]">We’re a UI/UX and Development company that is transforming and scaling businesses successfully through our services</p>
              </div> */}
              </div>
            </div>
            <div
              class="grid grid-cols-1 md:grid-cols-3 gap-6 sm:gap-8 lg:gap-12 justify-items-center sm:justify-items-stretch"
              data-aos="fade-up"
            >
              <div
                class="relative flex grid-cols-1 flex-col justify-around gap-[0px] border border-solid border-black bg-white px-6 py-8 [box-shadow:rgb(0,_0,_0)_9px_9px] max-[767px]:p-8 rounded-2xl mb-8 lg:mb-4"
                data-aos="fade-up"
              >
                <div class="absolute bottom-[auto] left-[auto] top-[-32px] flex h-16 w-16 flex-col items-center justify-center border border-solid border-[#9b9b9b] bg-white [box-shadow:rgb(0,_0,_0)_0px_5px] rounded-full right-4 lg:right-8">
                  <img
                    src="https://assets.website-files.com/63904f663019b0d8edf8d57c/639157f1a197859a6cd7f265_image%203.png"
                    alt=""
                    class="relative z-[1] inline-block h-8 max-w-full"
                  />
                  <div class="absolute z-0 h-8 w-8 border border-[#FDFBD4] bg-[#FDFBD4] rounded-full"></div>
                </div>
                <div class="text-xl font-semibold mb-4">Website design</div>
                <p>
                  RaveUX can move quickly from wireframe to high fidelity
                  mockups to help achieve your product goals.
                </p>
                <a
                  className="mt-5 px-4 py-2 text-black font-medium bg-[#ADD8E6] rounded-full inline-flex items-center  duration-150  md:inline-flex   outline-none  focus:shadow-none focus:ring-2 ring-offset-2 ring-[#FDFBD4] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110"
                  href="https://cal.com/raveux"
                >
                  Book a Call
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 ml-1 duration-150"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M13 7l5 5m0 0l-5 5m5-5H6"
                    />
                  </svg>
                </a>
              </div>
              <div
                class="relative flex grid-cols-1 flex-col justify-around gap-[0px] border border-solid border-black bg-white px-6 py-8 [box-shadow:rgb(0,_0,_0)_9px_9px] max-[767px]:p-8 rounded-2xl mb-8 lg:mb-4"
                data-aos="fade-up"
              >
                <div class="absolute bottom-[auto] left-[auto] top-[-32px] flex h-16 w-16 flex-col items-center justify-center border border-solid border-[#9b9b9b] bg-white [box-shadow:rgb(0,_0,_0)_0px_5px] rounded-full right-4 lg:right-8">
                  <img
                    src="https://assets.website-files.com/63904f663019b0d8edf8d57c/63915859fa889834c4f1ff92_image%203-2.png"
                    alt=""
                    class="relative z-[1] inline-block h-8 max-w-full"
                  />
                  <div class="absolute z-0 h-8 w-8 border border-[#FDFBD4] bg-[#FDFBD4] rounded-full"></div>
                </div>
                <div class="text-xl font-semibold mb-4">
                  Graphic design
                </div>
                <p>
                  Our team has extensive experience building digital experiences
                  for B2B and consumer facing clients.
                </p>
                <a
                  className="mt-5 px-4 py-2 text-black font-medium bg-[#ADD8E6] rounded-full inline-flex items-center  duration-150  md:inline-flex   outline-none  focus:shadow-none focus:ring-2 ring-offset-2 ring-[#FDFBD4] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110"
                  href="https://cal.com/raveux"
                >
                  Book a Call
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 ml-1 duration-150"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M13 7l5 5m0 0l-5 5m5-5H6"
                    />
                  </svg>
                </a>
              </div>

              <div
                class="relative flex grid-cols-1 flex-col justify-around gap-[0px] border border-solid border-black bg-white px-6 py-8 [box-shadow:rgb(0,_0,_0)_9px_9px] max-[767px]:p-8 rounded-2xl mb-8 lg:mb-4"
                data-aos="fade-up"
              >
                <div class="absolute bottom-[auto] left-[auto] top-[-32px] flex h-16 w-16 flex-col items-center justify-center border border-solid border-[#9b9b9b] bg-white [box-shadow:rgb(0,_0,_0)_0px_5px] rounded-full right-4 lg:right-8">
                  <img
                    src="https://assets.website-files.com/63904f663019b0d8edf8d57c/6391585b7b7cd87baef5e9ec_image%203-1.png"
                    alt=""
                    class="relative z-[1] inline-block h-8 max-w-full"
                  />
                  <div class="absolute z-0 h-8 w-8 border border-[#FDFBD4] bg-[#FDFBD4] rounded-full"></div>
                </div>
                <div class="text-xl font-semibold mb-4">
                  Social Media Design
                </div>
                <p>
                  With minimal guidance, our designers can create social media
                  content that WOWs and work within established guidelines.
                </p>
                <a
                  className="mt-5 px-4 py-2 text-black font-medium bg-[#ADD8E6] rounded-full inline-flex items-center  duration-150  md:inline-flex   outline-none  focus:shadow-none focus:ring-2 ring-offset-2 ring-[#FDFBD4] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110"
                  href="https://cal.com/raveux"
                >
                  Book a Call
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 ml-1 duration-150"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M13 7l5 5m0 0l-5 5m5-5H6"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WorkSection;
