import React from "react";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import StatsBanner from "../components/StatsBanner";
import Pros from "../components/Pros";
import Portfolio from "../components/Portfolio.js";
import WorkSection from "../components/WorkSection.js";
import Access from "../components/Access.js"
import QANew from "../components/QANew.js";
import TeamImage from "../components/TeamImage.js";
import Horizontal from "../components/Horizontal.js";
import Reviews from "../components/Reviews.js";
import DesignSamples from "../components/DesignSamples.js";
import ShortConnect from "../components/ShortConnect.js";
import { Helmet } from "react-helmet";
import EmailPopUp from "../components/EmailPopUp.js";


function Home() {

  return (
    <div class="overflow-visible">
   
    <Helmet>
   <script>
    {`
    (function(w,d,e,u,f,l,n){w[f]=w[f]||function(){(w[f].q=w[f].q||[])
    .push(arguments);},l=d.createElement(e),l.async=1,l.src=u,
    n=d.getElementsByTagName(e)[0],n.parentNode.insertBefore(l,n);})
    (window,document,'script','https://assets.mailerlite.com/js/universal.js','ml');
    ml('account', '950404');
     `}
   </script>
</Helmet>



    <Hero />
    <div className="flex items-center justify-center">
    <Horizontal/>
    </div>
      <StatsBanner />
      <EmailPopUp/>
      <TeamImage/>
      <Portfolio />
      <WorkSection/>
      <Pros />
      {/* <DesignSamples/> */}
      <Access/>
      <QANew/>
      <Reviews/>
      <ShortConnect/>
      <Footer />



    </div>
  );
}

export default Home;
