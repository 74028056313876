import { useEffect } from "react";
import PortfolioVideo1 from "../assets/PortfolioVideo1.mp4";
import PortfolioVideo2 from "../assets/PortfolioVideo2.mp4";
import PortfolioVideo3 from "../assets/PortfolioVideo3.mp4";
import PortfolioImage3 from "../assets/PortfolioImage3.jpeg";
import Military3 from "../assets/Military3.png";

import Military16 from "../assets/Military16.png";

import AOS from "aos";
import "aos/dist/aos.css";

function Portfolio() {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  });
  return (
    <section
      className="py-4 text-white inset-0 -z-10 h-full w-full bg-white"
      id="Project"
    >
      <div className="max-w-screen-xl mx-auto px-4 md:px-8" data-aos="fade-up">
        <div className="relative mx-auto sm:text-left mt-14 questrial-regular">
          <h3 className="text-md text-black border-[1px] rounded-0 border-black w-36 p-2 flex items-center justify-center mb-4 hover:-translate-y-1 hover:scale-100 duration-300">
            Our work
          </h3>
        </div>
      </div>

      <div
        class="container mx-auto px-5 py-2 lg:px-32 lg:pt-14 mb-10"
        data-aos="fade-up"
      >
        <div class="-m-1 flex flex-wrap md:-m-2" data-aos="fade-up">
          <div class="flex w-1/2 flex-wrap">
            <div class="w-1/2 group-hover:opacity-50 outline-none shadow-md focus:shadow-none focus:ring-2 ring-offset-2  transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300">
              <video
                class="block h-full w-full object-cover object-center"
                src={PortfolioVideo1}
                autoPlay
                loop
                muted
              ></video>
            </div>

            <div class="w-1/2 outline-none shadow-md focus:shadow-none focus:ring-2 ring-offset-2  transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300">
              <img
                alt="gallery"
                class="block h-full w-full object-cover object-center"
                src={Military3}
              />
            </div>
            <div class="w-full outline-none shadow-md focus:shadow-none focus:ring-2 ring-offset-2  transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300">
              <img
                alt="gallery"
                class="block h-full w-full object-cover object-center"
                src={PortfolioImage3}
              />
            </div>
          </div>
          <div class="flex w-1/2 flex-wrap ">
            <div class="w-full outline-none shadow-md focus:shadow-none focus:ring-2 ring-offset-2  transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300">
              <video
                class="block h-full w-full object-cover object-center"
                src={PortfolioVideo3}
                autoPlay
                loop
                muted
              ></video>
            </div>
            <div class="w-1/2 outline-none shadow-md focus:shadow-none focus:ring-2 ring-offset-2  transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300">
              <img
                alt="gallery"
                class="block h-full w-full object-cover object-center"
                src={Military16}
              />
            </div>

            <div class="w-1/2 outline-none shadow-md focus:shadow-none focus:ring-2 ring-offset-2  transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300">
              <video
                class="block h-full w-full object-cover object-center"
                src={PortfolioVideo2}
                autoPlay
                loop
                muted
              ></video>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Portfolio;
